import React from 'react';
import SignUp from './pages/register';
import SignIn from './pages/login';
import Home from './pages/home';
import LandingPage from './pages/landing-page';
import FaQ from './pages/FaQComp';


/**
 * @var ROUTERS adalah variable router aplikasi
 */
const ROUTERS = [
  {
    path: '/register',
    Component: SignUp,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/login',
    Component: SignIn,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/',
    Component: LandingPage,
    exact: true,
    isPrivate: false,
  },
  {
    path: '/faq',
    Component: FaQ,
    exact: true,
    isPrivate: false,
  },
  
];

export default ROUTERS;
