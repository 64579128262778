/**
 * @var WHITE_LIST is variable to declare store reducer save in browser;
 */
const WHITE_LIST = [
  'StatusPageReducer',
];

export default {
  WHITE_LIST,
};
